<template lang="pug">
  v-dialog(
    :value="value"
    @input="$emit('input', $event)"
    :max-width="maxWidth"
    transition="dialog-bottom-transition"
    content-class="modal"
  )
    slot
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    },
    app: Object,
    maxWidth: String
  }
}
</script>

<style lang="scss">
.modal {
  .card {
    &.v-card {
      background-color: $body-bg;
    }

    &__title.v-card__title, .v-card__actions {
      padding: 8px;
      padding-left: 16px;
      font-family: $font-title;
      font-size: 16px;
      color: $title;
      justify-content: space-between;
    }

    & .v-card__title {
      box-shadow: 0 1px 0 0 #f4f7f9;
    }

    & .v-card__actions {
      box-shadow: 0 -1px 0 0 #f4f7f9;
    }

    &__text.v-card__text {
      padding: 8px;
      padding-left: 16px;
    }
  }
}
</style>
